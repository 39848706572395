/**
 * Возвращает функцию, которая позволяет удобнее работать с setTimeout
 * при создании списков последовательных действий.
 *
 * @param init_time Смещение первого таймера. По умолчанию 0
 * @returns {function(*, *=): (number | function)}
 * @constructor
 */
function OffsetTimeout(init_time) {
    let total = Math.abs(init_time || 0);

    return (time_ms, func) => setTimeout(func, total += time_ms);
}

function assign(obj_a, obj_b) {
    return !!obj_b ? assign_object(obj_a, obj_b) : Object.assign({}, obj_a);

    function assign_object(obj_a, obj_b) {
        let ret = Object.assign({}, obj_a);
        for (let key in obj_b) {
            ret[key] =
                typeof obj_b[key] === "object" && typeof ret[key] === "object" ?
                    assign_object(ret[key], obj_b[key]) :
                    obj_b[key];
        }

        return ret;
    }

}

function rand_elem(list, count) {

    let ret = [];
    for (let i = 0; i < (count || 1); i++) {
        ret.push(Array.isArray(list) ? arr(list) : obj(list));
    }

    return ret.length === 1 ? ret[0] : ret;

    function obj(list) {
        let key = Object.keys(list);
        key = key.splice(rand(0, key.length - 1), 1);

        let item = list[key];
        delete list[key];

        return item;
    }

    function arr(list) {
        return list.splice(rand(0, list.length - 1), 1)[0];
    }
}

function rand(min, max) {
    min = Math.floor(min) || 0;
    max = Math.floor(max) || Number.MAX_SAFE_INTEGER;
    return Math.floor(min + Math.random() * (max + 1 - min));
}

function logb(a, b) {
    return Math.log(a) / Math.log(b || 10);
}


/**
 * Shuffles array in place. ES6 version
 * https://stackoverflow.com/a/6274381
 * @param {Array} a items An array containing the items.
 */
function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}


function Timer() {
    let total = 0;
    let last_ts = new Date();
    let paused = true;

    this.resume = function () {
        if (paused) {
            last_ts = new Date();
            paused = false;
        }

        return this.time();
    };

    this.pause = function () {
        if (!paused) {
            total += new Date() - last_ts;
            paused = true;
        }
        return this.time();
    };

    this.time = function () {
        return paused ? (total) : (new Date() - last_ts + total);
    };

    this.reset = function () {
        let time = this.time();

        total = 0;
        last_ts = new Date();

        return time;
    };

    this.resume();
}

function Timeout(callback, delay, paused, progressbar) {
    let timerId, start, remaining = delay;
    progressbar = Object.assign({pause: () => 1, resume: () => 1, reset: () => 1}, progressbar || {});

    this.pause = function () {
        window.clearTimeout(timerId);
        remaining -= new Date() - start;
        progressbar.pause();
    };

    this.resume = function () {
        start = new Date();
        window.clearTimeout(timerId);
        timerId = window.setTimeout(callback, remaining);
        progressbar.resume();
    };

    this.reset = function (new_delay) {
        remaining = new_delay || delay;
        progressbar.reset(new_delay);
        this.resume();
    };

    this.time_passed = function () {
        return new Date() - start;
    };

    this.time_left = function () {
        return remaining - this.time_passed();
    };

    if (!paused)
        this.resume();
}

function Progressbar($element, time, paused) {

    this.pause = () => {
        $element.removeClass('move');
    };

    this.resume = () => {
        $element.addClass('move');
        $element.css('animation-duration', `${time}ms`);
    };

    this.reset = (new_time) => {
        if (new_time)
            time = new_time;

        $element.readdElem();
        this.resume();
    };

    if (!paused)
        this.resume();

}

function nextTick(cb) {
    cb = typeof cb === 'function' ? cb : new Function();
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(cb());
        }, 1);
    })
}

$.fn.readdElem = function () {
    let clone = $(this).clone();
    $(this).replaceWith(clone);
    return this[0] = clone[0];

};