(async function (app) {
    /******************

     init

     ******************/

    $(document).ready(() => {
        app.init();
    });


    app.stageHistory = [];
    app.stageActions = {};


    document.addEventListener("backbutton", function () {
        $('[data-stage]:visible [back-action]').click();
    }, false);

    /******************

     functions

     ******************/

    app.init = function init() {
        app.showStage('main');
    };


    app.showStage = function showStage(stage_info) {
        stage_info = typeof stage_info === 'string' ? {stage: stage_info} : stage_info;
        let stage = stage_info.stage;
        let header = stage_info.header || stage_info.stage;

        $('[data-stage], [data-header]').removeClass('active');
        $(`[data-stage="${stage}"], [data-header="${header}"]`).addClass('active');

        app.stageHistory.push({stage, header});

        if (app.stageActions[stage])
            app.stageActions[stage]({
                type: stage_info.type || 'open'
            });
    };

    app.showStagePrev = function showStagePrev(stage_info) {
        app.stageHistory.pop();// remove current stage

        let stage = app.stageHistory.pop();
        stage = stage || {stage: 'main'};

        stage.type = 'back';

        app.showStage(Object.assign(stage, stage_info || {}));
    }

})(window.app = window.app || {});
