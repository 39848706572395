(function (app) {
    let Tpl = app.tpl = {};

    Tpl.game_pause = function () {
        return '' +
            `
<div>
    <h1>Pause</h1>        
</div>
`;
    };

    Tpl.card_item = function (info) {
        // console.log(info);
        return `
        <div class="item" data-id="${info.item.id}" style="
                background-image: url(/assets/img/items/${info.item.file});
                transform: rotate(${info.rotate}deg) scale(${info.scale});
            "></div>
        `;
    }

    Tpl.game_loose = function (info) {
        return `
<div>
    <h1>You loose</h1>
    
    <center onclick="$.fancybox.close(); app.game.start();">restart</center>
</div>
        `;
    };

})(window.app = window.app || {});