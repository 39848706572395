(function (app) {

    let Game = app.game = {
        instance: {}
    };

    app.stageActions.game = function () {
        Game.start();
    };

    /********************

     Events

     *********************/

    $('#pause').click(function () {
        game_pause();
    });

    $('[data-card]').on('click', '.item', function () {
        item_click($(this));
    });


    /********************

     Functions

     *********************/

    function new_instance(settings) {
        let action = {
            score: ({value, target}) => {
                $('#game-score').attr('data-value', value);

                target.score = value;
            },
            rounds: ({value, target}) => {
                $('#game-rounds').attr('data-value', value);

                target.rounds = value;
            },

            time_total: ({value, target, receiver}) => {

                let time_round = target.time_round;

                // FIXME: не совсем корректно названы переменные.
                // В данном случае diff означает сложность(difficulty), а dif разнцу (difference)

                // Увеличиваем сложность, не больше чем на четверть
                let time_dif = value - target.time_total;
                let time_left = time_round - time_dif;
                let diff_delta = Math.min(time_left / time_round, 0.05);

                target.diff += diff_delta;

                // Максимум = 1
                target.diff = Math.min(target.diff, 1);

                // Уменьшаем время, но не слишком сильно
                let time_round_delta = Math.min(time_left * diff_delta, 1000);

                target.time_round -= (time_round_delta > 200 ? time_round_delta : 0);

                receiver.rounds++;
                receiver.score += Math.ceil(target.score_round * (1 + target.diff) * Math.ceil(time_left / time_round));

                target.time_total = value;

                // console.log(JSON.stringify(target, null, '  '));
            },

            // Read only параметры.
            diff: () => console.error('Сложность пересчитывается за счёт выставления time_total.'),
            score_round: () => console.error('Выставляется при инициалищации'),
            time_round: () => console.error('Неа, через time_total'),
        };


        let instance = assign(app.cfg.game.instance);

        instance.timer = new Timeout(
            game_end,
            instance.time_round,
            true,
            new Progressbar(
                $('#progress'),
                instance.time_round,
                true
            )
        );

        return new Proxy(
            Object.assign(instance, settings),
            {
                set: (target, prop, value, receiver) => {

                    // Проверяем есть для данного параметра действие и вызываем его.
                    // Или просто, выставляем
                    if (action[prop]) {
                        action[prop]({target, prop, value, receiver});
                    } else {
                        target[prop] = value;
                    }

                    return true;
                }
            }
        );

    }

    Game.start = function () {
        Game.instance = new_instance();
        Game.instance.score = 0; // для сброса значения в html

        // setInterval(() => Game.instance.score += parseInt(Math.random() * 10000), 1000);

        Game.new_round();
    };

    Game.new_round = function (opts) {
        // Game.instance.rounds++;

        Game.instance.timer.reset(Game.instance.time_round);

        // console.log(assign(Game.instance));

        let params = Game.instance.params = get_round_params(Game.instance);

        if (params.pause)
            Game.instance.timer.pause();

        let cards = get_cards(params);

        print_cards(cards);
    };

    function get_round_params({diff}) {

        let diff_list = Object.keys(app.cfg.game.diff_params);

        let params = {};
        diff_list
            .sort((a, b) => a - b) // 0 -> 1
            .every((current_diff) => {

                // console.log(current_diff);

                if (current_diff > diff) return false;

                params = assign(params, app.cfg.game.diff_params[current_diff]);

                return true;
            });

        params = compute(params);

        if (Math.random() <= params.color.chance) {
            params.imgs = get_images_index_by_color(rand_elem(["yellow", "violett", "turquoise", "rose", "red", "orange", "grey", "green", "dark", "blue"]));
            params.min = params.color.min || params.min;
            params.max = params.color.max || params.max;
        }

        console.log(params);

        return params;


        function compute(obj) {
            for (let key in obj) {
                if (!obj.hasOwnProperty(key)) continue;
                if (typeof obj[key] === "function")
                    obj[key] = obj[key](Game.instance);
                if (typeof obj[key] === "object")
                    obj[key] = compute(obj[key]);
            }
            return obj;
        }
    }

    function print_cards(cards) {
        cards.forEach((card, index) => {
            let $card = $(`[data-card="${index}"]`);

            console.log($card);
            $card.html(
                // получаем html для каждой карточки и слеиваем
                card
                    .map(item => app.tpl.card_item(item))
                    .join('')
            );
            $card.attr('data-count', card.length);
        });
    }


    function get_cards(opts) {
        opts = Object.assign({
            imgs: get_images_index_by_id(),
            min: 2,
            max: 6,
            rotate: 1,
            resize: 1,
        }, opts);

        let cards = [
            // https://stackoverflow.com/a/20956445
            [...new Array(rand(opts.min, opts.max))],
            [...new Array(rand(opts.min, opts.max))],
        ];

        cards[1][0] = cards[0][0] = rand_elem(opts.imgs);

        for (let i = 0; i < cards.length; i++) {
            for (let k = 1; k < cards[i].length; k++) {
                cards[i][k] = rand_elem(opts.imgs);
            }
            cards[i] = cards[i].map(item => ({
                item: item,
                rotate: Math.random() * opts.rotate * 180 * (Math.random() > 0.5 ? -1 : 1),
                scale: 1 - Math.random() * opts.scale * 0.7,
            }));

            shuffle(cards[i]);
        }

        //console.log(cards);
        return cards;
    }

    function item_click($item) {
        let item_id = $item.attr('data-id');
        let is_true = $(`.card .item[data-id="${item_id}"]`).length == 2;

        if (!is_true)
            return game_end();

        round_end();
        Game.new_round();
    }


    function round_end() {
        let timer = Game.instance.timer;
        timer.pause();

        Game.instance.time_total += timer.time_passed();
    }

    function game_end() {
        round_end();

        app.alert.open(app.tpl.game_loose());
    }

    function game_pause() {
        app.alert.open(app.tpl.game_pause(), {
            afterClose: () => game_resume()
        });
        Game.instance.timer.pause();
    }

    function game_resume() {
        Game.instance.timer.resume();
    }


})(window.app = window.app || {});

