/**********************************************************************/
/**********************************************************************/
/*
    Используется только для разработки.
    Не подразумевается грузить его клиенту.
*/
/**********************************************************************/
/**********************************************************************/

(function (app) {

    let Dev = app.dev = {};

    $(document).ready(() => {
        anim_title();
        dev_scenario();
    });

    console.log(
        'loaded', parseInt(Math.random() * 100000),
        'version', app.version,
        'DEV_MODE', DEV_MODE
    );

    function anim_title() {
        let loaded = new Date();
        setInterval(function () {
            let time = (new Date()) - loaded;
            let title_base = `Same ${window.app.version} ${time}ms`;

            $('title').html(`${title_base}`);
        }, 300);
    }


    // note: dev only
    let dev_scenario = () => {
        let t = new OffsetTimeout();

        t(0, () => {
            $('#start').click();
        });

        // t(1000, () => {
        //     window.open('https://getemoji.com/', '_blank')
        // });




        // t(500, () => {
        //     app.game.start();
        // });

        // t(500, () => setInterval(function () {
        //     this.count = this.count || 0;
        //     let count = this.count++ % 6 + 1;
        //
        //     let items = new Array(count).fill(`<div class="item"></div>`, 0, count);
        //
        //     $('.card').attr('data-count', count).html(items.join(''));
        // }, 1000));
        //
        // t(500, () => setInterval(function () {
        //     this.val = this.val || 0;
        //     $('#game-score').attr('data-value', this.val += parseInt(Math.random() * 100));
        // }, 10));

    };

    // note: dev only
    app.getStages = function () {
        let list = {
            stages: [],
            headers: []
        };
        $('[data-stage], [data-header]').each(function () {
            let name = null;
            if (name = $(this).attr('data-stage')) {
                list.stages.push(name);
            }
            if (name = $(this).attr('data-header')) {
                list.headers.push(name);
            }
        });

        return list;
    };


})(window.app = window.app || {});