(function (app) {

    let Cfg = app.cfg = {};


    $.fancybox.defaults = Object.assign($.fancybox.defaults, {
        touch: false, // закрытие свайпом
        clickSlide: false, // закрытие на клик вне окна
        clickOutside: false, // закрытие на клик вне окна
    });


    let Game = Cfg.game = {};

    Game.instance = {
        // сложность
        diff: 0,
        score: 0,
        rounds: 0,
        // Базовое колличество очков за успешный раунд
        score_round: 100,
        time_round: 5000,
        time_total: 0,
        params: {}
    };

    /*
      Важно учитывать, что параметры "наслаиваются"
      Т.е.:
          0.2: {min: 2, max: 5},
          0.3: {min: 4},
          0.4: {        max: 6},

      Даст:
          {min: 2, max: 5} на сложности >= 0.2,
          {min: 4, max: 5} на сложности >= 0.3,
          {min: 4, max: 6} на сложности >= 0.4,

      Такое же правило применяется к вложенным объектам

      Если вместо значения подать функцию, то она будет вызвана перед использованием объекта и должна вернуть
      начение соответвующее параметру в котором она находится
      Пример:
            {
                min:1,
                max:3,
                resize: ({diff}) => Math.min(diff,0.2) // т.е. resize будет зависеть от сложности, но не более 20%
            }
     */
    Game.diff_params = {
        // Первый ход
        0: {
            min: 2,
            max: 2,
            pause: true, // Стартует на паузе
            color: { // Шанс цветного уровня
                chance: 0,
            },
            rotate: 0,
            scale: 0,
        },

        // Второй ход
        0.001: {
            min: 2,
            max: 2,
            pause: false,
            color: {
                chance: 0.20,
                min: 2,
                max: 3
            },
            rotate: ({diff}) => diff,
            scale: ({diff}) => diff,
        },

        // 12,5%
        0.125: {},

        // 25%
        0.25: {
            min: 2,
            max: 3,
            color: {
                chance: 0.25,
                min: 2,
                max: 3
            },
        },

        // 32,5%
        0.325: {
            color: {
                chance: 0.25,
            }
        },

        // 50%
        0.5: {
            min: 3,
            max: 3,
            color: {
                chance: 0.25,
                min: 3,
                max: 3
            }
        },

        // 62,5%
        0.625: {
            min: 3,
            max: 4,
            color: {
                chance: 0.25,
                min: 3,
                max: 4
            }
        },

        // 75%
        0.75: {
            min: 4,
            max: 5,
            color: {
                chance: 0.35,
                min: 4,
                max: 5
            }
        },

        // 82,5%
        0.825: {
            min: 5,
            max: 6,
            color: {
                chance: 0.40,
                min: 5,
                max: 6
            }
        },

        // 100% Максимальная сложность
        1: {
            min: 6,
            max: 6,
            color: {
                chance: 0.5,
                min: 6,
                max: 6
            },
            rotate: 1,
            scale: 1,
        },
    };

})(window.app = window.app || {});